<h3>{{ isFormEnabled ? "Enter Search Criteria" : "Search Criteria Review" }}</h3>
<div class="padding">
    <form [formGroup]="searchCriteriaDetails">
        <div class="flex-container" *ngIf="shouldDisplayExactSimilar">
            <div class="flex-item form-field">
                <mat-radio-group [formControlName]="'exactSimilar'" name="exact-similar"
                    aria-label="Exact or similars radio option">
                    <mat-radio-button class="radio-button" [value]="false">Include Similars <span *ngIf="displayIndividualFields && shouldDisplaySpecificNonSpecific">(Non-Specific)</span></mat-radio-button>
                    <mat-radio-button class="radio-button" [value]="true">Exact Only <span *ngIf="displayIndividualFields && shouldDisplaySpecificNonSpecific">(Specific)</span></mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        
        <div class="flex-container">
            <div class="flex-item form-field">
                <mat-radio-group [formControlName]="'partyTypeID'" name="party-type"
                    aria-label="Debtor Individual or Business radio option">
                    <mat-radio-button class="radio-button" [value]="individualDebtor">Individual
                        Debtor</mat-radio-button>
                    <mat-radio-button class="radio-button" [value]="businessDebtor">Business Debtor</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div *ngIf="displayIndividualFields" class="flex-container">

            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>First Name</mat-label>
                    <input matInput [formControlName]="'firstName'" name="first-name" placeholder="First Name">
                </mat-form-field>
                <lib-error-bubble [control]="searchCriteriaDetails.get('firstName')!"
                    [message]="firstNameError$"></lib-error-bubble>
            </div>

            <div *ngIf="!shouldDisplaySpecificNonSpecific || (shouldDisplaySpecificNonSpecific && searchCriteriaDetails.get('exactSimilar')!.value == true)"
                class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>{{shouldOnlyAllowMiddleInitial ? 'Middle Initial' : 'Middle Name'}}</mat-label>
                    <input matInput [formControlName]="'middleName'" name="middle-name"
                        placeholder="{{shouldOnlyAllowMiddleInitial ? 'Middle Initial' : 'Middle Name'}}">
                </mat-form-field>
                <lib-error-bubble [control]="searchCriteriaDetails.get('middleName')!"
                    [message]="middleNameError$"></lib-error-bubble>
            </div>

            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Last Name</mat-label>
                    <input matInput [formControlName]="'lastName'" name="last-name" placeholder="Last Name">
                </mat-form-field>
                <lib-error-bubble [control]="searchCriteriaDetails.get('lastName')!"
                    [message]="lastNameError$"></lib-error-bubble>
            </div>
        </div>

        <div *ngIf="displayIndividualFields" class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field
                    *ngIf="shouldDisplayDateOfBirth && (!shouldDisplaySpecificNonSpecific || (shouldDisplaySpecificNonSpecific && searchCriteriaDetails.get('exactSimilar')!.value == true))"
                    class="form-field flex-item" appearance="outline">
                    <mat-label>Birth Date</mat-label>
                    <input matInput [matDatepicker]="dateOfBirth" [formControlName]="'dateOfBirth'" name="date-of-birth"
                        placeholder="YYYY-MM-DD">
                    <mat-datepicker-toggle matSuffix [for]="dateOfBirth"></mat-datepicker-toggle>
                    <mat-datepicker #dateOfBirth></mat-datepicker>
                </mat-form-field>
                <lib-error-bubble [control]="searchCriteriaDetails.get('dateOfBirth')!"
                    [message]="dateOfBirthError$"></lib-error-bubble>
            </div>
        </div>

        <div *ngIf="displayBusinessFields" class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Business Name</mat-label>
                    <input matInput [formControlName]="'busName'" name="business-name" placeholder="Business Name">
                </mat-form-field>
                <lib-error-bubble [control]="searchCriteriaDetails.get('busName')!"
                    [message]="busNameError$"></lib-error-bubble>
            </div>
        </div>

        <mat-expansion-panel *ngIf="shouldDisplayPostalCode || shouldDisplaySearchFromDate"
            [expanded]="shouldExpandAdditionalParameters()" (click)="this.hasUserExpandedAdditionalParameters = true"
            [class.mat-elevation-z1]="!panel.expanded" [class.mat-elevation-z0]="panel.expanded"
            #panel="matExpansionPanel">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h4>Additional Parameters</h4>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div *ngIf="shouldDisplayPostalCode" class="flex-container">
                <div class="validated-form-field-container flex-container">
                    <mat-form-field class="flex-item form-field" appearance="outline">
                        <mat-label>Postal Code</mat-label>
                        <input matInput [formControlName]="'postalCode'" name="postal-code" placeholder="Postal Code">
                    </mat-form-field>
                    <lib-error-bubble [control]="searchCriteriaDetails.get('postalCode')!"
                        [message]="postalCodeError$"></lib-error-bubble>
                </div>
            </div>

            <div *ngIf="shouldDisplaySearchFromDate" class="flex-container">
                <div class="validated-form-field-container flex-container">
                    <mat-form-field class="form-field flex-item" appearance="outline">
                        <mat-label>Exclude Registrations Previous To</mat-label>
                        <input matInput [matDatepicker]="searchFromDate" [formControlName]="'searchFromDate'"
                            name="search-from-date" placeholder="YYYY-MM-DD">
                        <mat-datepicker-toggle matSuffix [for]="searchFromDate"></mat-datepicker-toggle>
                        <mat-datepicker #searchFromDate></mat-datepicker>
                    </mat-form-field>
                    <lib-error-bubble [control]="searchCriteriaDetails.get('searchFromDate')!"
                        [message]="searchFromDateError$"></lib-error-bubble>
                </div>
            </div>
            <br />
            <br />
        </mat-expansion-panel>
    </form>
</div>