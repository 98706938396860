import { SearchStepper } from "../search-stepper";
import { StepValidationState } from "projects/reg-hub-client/src/interfaces/step";
import { BusinessSearchCriteriaComponent } from "projects/reg-hub-client/src/app/orders/business-search-criteria/business-search-criteria.component";
import { BusinessSearchReviewComponent } from "projects/reg-hub-client/src/app/orders/business-search-review/business-search-review.component";

export class _MBusinessSearchStepper extends SearchStepper {
    constructor() {
        super()

        this.steps = [
            { title: "Search Criteria", content: BusinessSearchCriteriaComponent, validationState: StepValidationState.DefaultState },
            { title: "Review", content: BusinessSearchReviewComponent, validationState: StepValidationState.DefaultState }
        ]
    }
}