import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Order, OrderManagerService, OrderRepositoryService, OrderStateRepositoryService, eOrderType, ePartyType, Party, eOrderStatusType, eResultState, ValidationService } from 'reg-hub-common';
import { Router } from '@angular/router';
import { ActionUIConfiguration } from 'projects/reg-hub-common/src/lib/interfaces/ui-configurations/action-ui-configuration';
import { DuplicateInComponent } from '../duplicate-in/duplicate-in.component';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.css']
})
export class ActionsComponent implements OnInit {
  @Input() order!: Order;
  @Input() uiConfiguration!: ActionUIConfiguration;
  protected baseOrderDetails!: FormGroup;
  protected isSaving: boolean = false;
  protected isActionable: boolean = false;

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private ordersRepo: OrderRepositoryService,
    private orderManager: OrderManagerService,
    private orderStateRepo: OrderStateRepositoryService,
    private router: Router,
    private validationService: ValidationService
  ) { }

  ngOnInit(): void {
    this.baseOrderDetails = this.formBuilder.group({
      referenceNumber: [this.order.referenceNumber, Validators.required],
      country: [this.order.country, Validators.required],
      jurisdiction: [this.order.jurisdiction, Validators.required],
      orderTypeID: [this.order.orderTypeID, Validators.required],
      originalOrderID: [this.order.id, Validators.required]
    });
    this.setIsActionable();
  }

  amend() {
    // replacer excludes properties with ID
    var copiedOrder = JSON.parse(JSON.stringify(this.order));
    copiedOrder.originalOrderID = this.order.id;
    copiedOrder.orderTypeID = eOrderType.amendment;
    copiedOrder.parties = copiedOrder.parties.filter((party: Party) => party.partyTypeID !== ePartyType.businessRegisteringAgent && party.partyTypeID !== ePartyType.individualRegisteringAgent);

    delete copiedOrder.id; // Remove ID from request

    this.requestOrder(eOrderType.amendment, copiedOrder);
  }

  renew() {
    const newOrderType = this.order.orderTypeID === eOrderType.basicRenewal ? eOrderType.basicRenewal : eOrderType.renewal;

    if (newOrderType === eOrderType.basicRenewal) {
      this.requestOrder(newOrderType, {
        referenceNumber: this.order.referenceNumber,
        jurisdiction: this.order.jurisdiction,
        country: this.order.country,
        orderTypeID: newOrderType,
        originalOrderID: this.order.id,
        lien: {}
      } as any);
    } else {
      this.requestOrder(newOrderType);
    }
  }

  discharge() {
    const newOrderType = this.order.orderTypeID === eOrderType.basicDischarge ||
      this.order.orderTypeID === eOrderType.basicRenewal ?
      eOrderType.basicDischarge :
      eOrderType.discharge;

    if (newOrderType === eOrderType.basicDischarge) {
      this.requestOrder(newOrderType, {
        referenceNumber: this.order.referenceNumber,
        jurisdiction: this.order.jurisdiction,
        country: this.order.country,
        orderTypeID: newOrderType,
        originalOrderID: this.order.id,
        lien: {}
      } as any);
    } else {
      this.requestOrder(newOrderType);
    }
  }

  edit() {
    const newStatus = {
      newStatus: "HistoricalDraft",
      status: "",
      message: "Cancelled from Reg Hub Client"
    };

    this.orderStateRepo.updateOrderState(this.order.id, newStatus)
      .subscribe(result => {
        this.isSaving = false;
        this.router.navigate([
          `new-registration/${this.order.id}`
        ]);
      });
  }

  duplicate() {
    const dialogRef = this.dialog.open(DuplicateInComponent, {
      data: this.order,
      minWidth: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'submitted') {
        this.isSaving = true;
      }
    });
  }

  update() {
    if (this.order.orderTypeID === eOrderType.businessReport) {
      this.isSaving = true;
      const newOrder = JSON.parse(JSON.stringify(this.order));
      newOrder.orderGroupID = this.order.orderGroup?.id;

      this.ordersRepo.createNewOrder(newOrder)
        .subscribe(result => {
          const originatingOrderID = this.order.id;
          this.isSaving = false;
          const order = (result as any).resource;
          this.order = order;
          this.orderManager.updateOrder(order);
          this.validationService.currentValidation$.next((result as any).validation);
          if (this.order.id) {
            this.router.navigate(['new-business-search', order.id], {
              queryParams: { originatingOrderID: originatingOrderID }
            });
          }
        });
    }
  }

  showDuplicateButton(): boolean {
    return (this.uiConfiguration.hasDuplicateIn ?? false) &&
      !(this.order.lien?.registrationResults?.some(res => res.expiryDate ? new Date() > res.expiryDate : false) ?? true);
  }

  showUpdateButton(): boolean {
    return (this.uiConfiguration.hasUpdate ?? false) && this.order.searchResult?.resultState?.id !== eResultState.noResult && this.isActionable;
  }

  requestOrder(orderTypeID: eOrderType, order?: Order): boolean {
    this.orderManager.updateParentOrder(this.order);

    this.baseOrderDetails.patchValue({
      orderTypeID: orderTypeID
    });

    this.isSaving = true;

    // Otherwise, create it
    this.ordersRepo.createOrder("orders", order ?? this.baseOrderDetails.value)
      .subscribe(result => {
        this.isSaving = false;
        const order = (result as any).resource;
        this.orderManager.updateOrder(order);
        if (this.order.id) {
          this.router.navigate([
            `new-registration/${order.id}`
          ]);
        }
      });

    // do validation here
    return true;
  }

  setIsActionable() {
    if (this.order.orderTypeID === eOrderType.businessReport) {
      this.isActionable = this.order.searchResult?.resultState?.id !== eResultState.noResult;
      return;
    }

    let registrationDate = new Date(this.order.referenceRegistrationInformation?.originalRegistrationDate ??
      this.order.lien!.registrationResults?.[0]?.registrationDate ??
      this.order.orderStatusUpdatedDate!);

    let isInfiniteTerm = this.order.lien?.isInfiniteTerm ?? false;
    let expired = false;
    if (!isInfiniteTerm) {
      let expiryDate = this.getExpiryDate(this.order, registrationDate);

      const now = new Date();

      const endOfExpiryDate = new Date(expiryDate);
      endOfExpiryDate.setHours(23, 59, 59, 999);

      expired = (endOfExpiryDate.getTime() - now.getTime()) <= 0;
    }

    this.isActionable = (this.order.orderStatusTypeID === eOrderStatusType.complete ||
      this.order.orderStatusTypeID === eOrderStatusType.historical) && !expired;
  }

  getExpiryDate(order: Order, registrationDate?: Date) {
    // If we have a requested expiry date use that
    if (order.lien?.expiryDate) {
      return new Date(order.lien.expiryDate);
    } else if (order.lien?.registrationResults?.at(0)?.expiryDate) {
      return new Date(order.lien.registrationResults.at(0)!.expiryDate!);
    } else if (order.referenceRegistrationInformation?.expiryDate && order.lien?.term) {
      return this.calculateExpiryDateFromTerm(order.referenceRegistrationInformation.expiryDate, order.lien.term);
    } else {
      // otherwise, calculate expiry date based on term
      return this.calculateExpiryDateFromTerm(registrationDate!, order.lien!.term!);
    }
  }

  calculateExpiryDateFromTerm(registrationDate: Date, term: number): Date {
    const expiryDate = new Date(registrationDate); // Clone the original date
    expiryDate.setFullYear(expiryDate.getFullYear() + term);
    return expiryDate;
  }
}
