import { Component, Input } from '@angular/core';
import { Order, ValidationProblem } from 'reg-hub-common';
import { eCriteriaVariation } from '../../services/stepper/steps/search-stepper';
import { SteppedComponent } from 'projects/reg-hub-client/src/interfaces/stepped-component';
import { StepValidationState } from 'projects/reg-hub-client/src/interfaces/step';

@Component({
  selector: 'app-asset-search-review',
  templateUrl: './asset-search-review.component.html',
  styleUrls: ['./asset-search-review.component.css']
})
export class AssetSearchReviewComponent extends SteppedComponent {
  @Input() criteriaVariations: eCriteriaVariation[] = []
  protected shouldDisplaySearchFromDate: boolean = false;
  protected shouldDisplayExactSimilar: boolean = false;

  protected override init(order: Order): void {

    this.shouldDisplaySearchFromDate = this.criteriaVariations.includes(eCriteriaVariation.SearchFromDate);
    this.shouldDisplayExactSimilar = this.criteriaVariations.includes(eCriteriaVariation.ExactSimilar);

    super.init(order);
    this.validate(this.order);
  }
  
  public override getValidationState(errors: ValidationProblem[] | undefined, order: Order): StepValidationState {
    return (errors?.length ?? 0) === 0 ? StepValidationState.ValidationSuccess : StepValidationState.ValidationError;
  }
}
