<div class="padding">
    <lib-static-notice text="Multi jurisdiction asset searches retrieve only exact matches from the registry."></lib-static-notice>
    <form [formGroup]="searchForm">
        <div class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Serial Number</mat-label>
                    <input matInput [formControlName]="'serialNumber'" name="serial-number" placeholder="Serial Number">
                </mat-form-field>
                <lib-error-bubble [control]="searchForm.get('serialNumber')!"
                    [message]="serialNumberError$"></lib-error-bubble>
            </div>
        </div>
    </form>
    
    <lib-multi-jurisdiction-select [orderTypes]="orderTypes" [initialJurisdictions]="orderJurisdictions"
        (selectedJurisdictions)="onJurisdictionSelected($event)"></lib-multi-jurisdiction-select>
</div>