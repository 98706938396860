import { Order, User, eOrderStatusType, eOrderType, ePartyType } from "reg-hub-common";

export class Constants {
    // url parameters
    public static corporationID = "corporationID";
    public static userGroupID = "userGroupID";
    public static userID = "userID";

    public static statusComplete = "Complete";

    public static returnToOriginatingOrder = "originating";
    public static stayOnNewOrder = "new";

    public static businessDebtor = 1;
	public static individualDebtor = 2;
	public static businessSecuredParty = 3;
	public static individualSecuredParty = 4;
	public static businessRegisteringAgent = 5
	public static individualRegisteringAgent = 6;

    public static getNormalizedPartyType(partyTypeInput: string): ePartyType | null {
        let partyType: ePartyType;
    
        if (typeof partyTypeInput === 'number') {
            partyType = partyTypeInput as ePartyType;
        } else if (typeof partyTypeInput === 'string') {
            partyType = partyTypeInput as ePartyType;
        } else {
            // Input is not a number or string, or doesn't match any enum value
            return null;
        }

        return partyType;
    }

    public static isDebtor(partyType: ePartyType): boolean {
        return partyType === ePartyType.businessDebtor || partyType === ePartyType.individualDebtor;
    }

    public static isSecuredParty(partyType: ePartyType): boolean {
        return partyType === ePartyType.businessSecuredParty || partyType === ePartyType.individualSecuredParty; 
    }

    public static isDealer(partyType: ePartyType): boolean {
        return partyType === ePartyType.businessDealer || partyType === ePartyType.individualDealer; 
    }

    public static isRegisteringAgent(partyType:  ePartyType): boolean {
        return partyType === ePartyType.businessRegisteringAgent || partyType === ePartyType.individualRegisteringAgent; 
    }

    public static ordersHubNegotiateUrl: string = "/Hubs/Orders/Negotiate";
}