import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Order, OrderRepositoryService } from 'reg-hub-common';

@Component({
  selector: 'app-pending-order-list',
  templateUrl: './pending-order-list.component.html',
  styleUrls: ['./pending-order-list.component.css']
})
export class PendingOrderListComponent {

  @Input() data: Order[] = [];
  @Input() total: number = 0;
  @Input() loading: boolean = false;
  
  protected displayedColumns = [
    'referenceNumber',
    'orderTypeID',
    'jurisdiction',
    'messages'
  ]

  constructor(
    protected repo: OrderRepositoryService,
    protected router: Router) { }

  protected onViewAll() {
    let params = {
      status: ['Pending','OutOfRegistryHours','AwaitingHighVolumeResults','RegistryOutage','Exception'],
      sortColumn: 'added',
      sortDirection: 'desc'
    }

    this.router.navigate(['/orders'], { queryParams: params })
  }
}
