<lib-validation-problems [saving$]="saving$" *ngIf="!printMode"></lib-validation-problems>

<div *ngIf="uiConfiguration.hasReferenceRegistrationInformation">
    <h3>Existing Registration Details</h3>
    <lib-existing-registration-details
    [uiConfiguration]="uiConfiguration.referenceRegistrationInformationUIConfiguration!">
    </lib-existing-registration-details>
</div>

<h3 *ngIf="uiConfiguration.hasDischargeDetails && uiConfiguration.dischargeDetailsConfiguration!.showDischargeType">
    {{order.orderType.name}} Details
</h3>
<div class="flex-item-no-padding" *ngIf="uiConfiguration.hasDischargeDetails && uiConfiguration.dischargeDetailsConfiguration!.showDischargeType">
    <div class="margin-bottom-5">
        <div class="flex-container" *ngIf="uiConfiguration.dischargeDetailsConfiguration!.showDischargeType">
            <span class="flex-item-3">Discharge Type:</span>
            <span class="flex-item-4" data-testid="lien-details-form-type-text">{{ dischargeType }}</span>
        </div>
    </div>
</div>

<lib-lien-results [uiConfiguration]="uiConfiguration.lienDetailsConfiguration!"
    [results]="order.lien?.registrationResults?.at(0)" *ngIf="isHistorical()"
    class="flex-item">
</lib-lien-results>