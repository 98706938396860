import { Component, NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from 'reg-hub-common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoggedOutComponent } from './auth/logged-out/logged-out.component';
import { NewRegistrationComponent } from './orders/new-registration/new-registration.component';
import { NewSearchComponent } from './orders/new-search/new-search.component';
import { SearchOrdersComponent } from './orders/search-orders/search-orders.component';
import { OrderComponent } from './orders/order/order.component';
import { CallbackComponent } from './auth/callback/callback.component';
import { BaseOrderInformationComponent } from './orders/base-order-information/base-order-information.component';
import { MyAccountComponent } from './auth/my-account/my-account.component';
import { InformationDocumentsComponent } from './information-documents/information-documents.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ClientGuard } from './auth-guard';
import { SystemEventsHistoryComponent } from './system-event-history/system-events-history.component';
import { NewBusinessSearchComponent } from './orders/new-business-search/new-business-search.component';
import { PrintOrderComponent } from './orders/print-order/print-order.component';
import { PrintComponent } from './navigation/print/print.component';
import { WrapperComponent } from './navigation/wrapper/wrapper.component';

const routes: Routes = [
  {
    path: '', component: WrapperComponent, children: [
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent, canActivate: [ClientGuard] },
      { path: 'new-registration', component: BaseOrderInformationComponent, canActivate: [ClientGuard] },
      { path: 'new-registration/:orderID', component: NewRegistrationComponent, canActivate: [ClientGuard] },
      { path: 'new-search', component: BaseOrderInformationComponent, canActivate: [ClientGuard] },
      { path: 'new-search/:orderID', component: NewSearchComponent, canActivate: [ClientGuard] },
      { path: 'new-business-search', component: BaseOrderInformationComponent, canActivate: [ClientGuard] },
      { path: 'new-business-search/:orderID', component: NewBusinessSearchComponent, canActivate: [ClientGuard] },
      { path: 'orders', component: SearchOrdersComponent, canActivate: [ClientGuard] },
      { path: 'management', loadChildren: () => import('./management/management.module').then(m => m.ManagementModule), canActivate: [ClientGuard] },
      { path: 'messages', loadChildren: () => import('./messages/messages.module').then(m => m.MessagesModule), data: { breadcrumb: { label: "Messages", url: "messages" } }, canActivate: [ClientGuard]  },
      { path: 'accounting', loadChildren: () => import('./accounting/accounting.module').then(m => m.AccountingModule), canActivate: [ClientGuard] },
      { path: 'reports', loadChildren: () => import('./reporting/reporting.module').then(m => m.ReportsModule), canActivate: [ClientGuard] },
      { path: 'collections', loadChildren: () => import('./collections/collections.module').then(m => m.CollectionsModule), canActivate: [ClientGuard] },
      { path: 'orders/:orderID', component: OrderComponent, canActivate: [ClientGuard] },
      { path: 'signout-callback-oidc', component: LoggedOutComponent },
      { path: 'callback', component: CallbackComponent, canActivate: [ClientGuard] },
      { path: 'my-account', component: MyAccountComponent, canActivate: [ClientGuard] },
      { path: 'information-documents', component: InformationDocumentsComponent, canActivate: [ClientGuard] },
      { path: 'contact-us', component: ContactUsComponent, canActivate: [ClientGuard] },
      { path: 'system-events', component: SystemEventsHistoryComponent, canActivate: [ClientGuard] },
      // Wildcard route for a 404 page
      { path: '**', component: PageNotFoundComponent, data: { breadcrumb: { label: '404', url: "" } } }
    ]
  },
  {
    path: 'print', outlet: 'print', component: PrintComponent, children: [
      { path: 'orders', component: PrintOrderComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
