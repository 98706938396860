import { trigger, state, style, transition, animate } from '@angular/animations';
import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { StepValidationState } from 'projects/reg-hub-client/src/interfaces/step';
import { SteppedComponent } from 'projects/reg-hub-client/src/interfaces/stepped-component';
import { ValidationProblem, Order, OrderManagerService, ValidationService, OrderGroupsService, ValidatedOrder, eOrderType, SlideDownAnimation } from 'reg-hub-common';
import { first, map, switchMap, takeUntil } from 'rxjs';
import { OrdersHubService } from '../../services/hubs/orders-hub/orders-hub.service';
import { Utilities } from 'reg-hub-common';

@Component({
  selector: 'app-multi-search-review',
  templateUrl: './multi-search-review.component.html',
  styleUrls: ['./multi-search-review.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ],
})
export class MultiSearchReviewComponent extends SteppedComponent {
  protected orders: ValidatedOrder[] = [];
  protected loading = false;
  protected validating = false;
  protected _expandedOrder: ValidatedOrder | null = null;

  displayedColumns: string[] = [
    'criteria',
    'country',
    'jurisdiction',
    'validation'
  ];

  constructor(
    orderManagerService: OrderManagerService,
    validationService: ValidationService,
    private orderGroupsService: OrderGroupsService,
    private orderHub: OrdersHubService) {
    super(orderManagerService, validationService);
  }

  override ngOnInit(): void {
    this.shouldSaveOrderOnStepChange = false;
    this.loading = true;

    super.ngOnInit();

    //Need to use the order manager saving observable as the criteria step doesn't have access to the 
    //stepper saving observable and it needs to save potentially all the orders.
    this.orderManager.saving$.pipe(
        takeUntil(this.onDestroy$),
        first(val => val === false),
        switchMap(() => this.orderGroupsService.getOrderGroupOrdersWithValidation(this.order.orderGroup?.id ?? '', 100, new HttpParams().append('isChildSearch', true))),
        map((resources: ValidatedOrder[]) => resources
          .filter(r => r.resource.orderTypeID !== eOrderType.assetMultiSearch)
          .sort(Utilities.sortByJurisdiction(r => r.resource.jurisdiction))))
      .subscribe(orders => {
        if (orders.length === 0) {
          //Dummy validation message for no orders to disable button
          this.validationService.currentValidation$.next({
            errors: [ { path: '', message: 'No jurisdictions selected', userFriendlyMessage: 'No jurisdictions selected' }]
          })
        } else {
          //Disable submit button by passing in all validation errors
          this.validationService.currentValidation$.next({
            errors: orders.flatMap(o => o.validation.errors ?? [])
          });
        }

        this.orders = orders;
        this.loading = false;

        orders.forEach(o => this.orderHub.joinOrderGroup(o.resource));
      });
  }

  protected hasErrors(order: ValidatedOrder) {
    return order.validation.errors && order.validation.errors.filter(err => err.showForClient).length > 0;
  }

  protected hasWarnings(order: ValidatedOrder) {
    return order.validation.warnings && order.validation.warnings.filter(warn => warn.showForClient).length > 0;
  }

  public onRowClick(order: ValidatedOrder) {
    if (this.hasErrors(order) || this.hasWarnings(order)) {
      this._expandedOrder = this._expandedOrder === order ? null : order
    }
  }

  public override getValidationState(errors: ValidationProblem[] | undefined, order: Order): StepValidationState {
    return StepValidationState.ValidationSuccess;
  }
}
