import { Component, OnInit } from '@angular/core';
import { ClientAuthService } from './services/auth/client-auth.service';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { IdleCountdownDialogComponent } from 'reg-hub-common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { idleTimers } from '../environments/environment';
import { NavigationHistoryService } from './services/navigation-history-service/navigation-history.service';
import { Router } from '@angular/router';
import { OrdersHubService } from './services/hubs/orders-hub/orders-hub.service';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'reg-hub-client';
  protected isAuthenticated: boolean = false;

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = undefined;
  countdown: number = 0;
  dialogRef: MatDialogRef<IdleCountdownDialogComponent> | null = null;

  constructor(
    private router: Router,
    private authService: ClientAuthService,
    private idle: Idle,
    private dialog: MatDialog,
    private navigationService: NavigationHistoryService,
    private ordersHub: OrdersHubService,
    private matIconRegistry: MatIconRegistry,) {
      idle.setIdle(idleTimers.idleTimeoutInSeconds);
      idle.setTimeout(idleTimers.logoutWarningCountdownInSeconds);
      // Set default interrupts (mouse, keyboard, touch)
      idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

      idle.onIdleEnd.subscribe(() => {
        this.idleState = 'No longer idle.';
        this.resetIdleTimer();
        this.closeDialog();
      });

      idle.onTimeout.subscribe(() => {
        this.idleState = 'Timed out!';
        this.timedOut = true;
        this.authService.logOut();
      });

      idle.onIdleStart.subscribe(() => this.idleState = "You've gone idle!");
      idle.onTimeoutWarning.subscribe((countdown) => {
        this.countdown = countdown;
        this.openOrUpdateIdleCountdownDialog(countdown);
      });
    }

  ngOnInit(): void {
    this.matIconRegistry.setDefaultFontSetClass('material-symbols-rounded');

    this.resetIdleTimer();

    this.authService.completeAuthentication().then(() => {
      this.isAuthenticated = true;
      setTimeout(() => {
        this.ordersHub.connect();
      }, 1000);
    })
      .catch((error) => {
        if (!this.authService.isLoggedIn() && !this.router.url.startsWith("/callback")) {
          this.authService.logIn()
        } else {
          this.isAuthenticated = true;
        }
      });
  }

  resetIdleTimer(): void {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  openOrUpdateIdleCountdownDialog(countdown: number): void {
    if (this.dialogRef) {
      // Dialog is already open, update countdown
      this.dialogRef.componentInstance.data.countdown = countdown;
    } else {
      // Open a new dialog
      this.dialogRef = this.dialog.open(IdleCountdownDialogComponent, {
        width: '300px',
        data: { countdown }
      });

      this.dialogRef.afterClosed().subscribe(() => {
        this.dialogRef = null;
        this.resetIdleTimer();
      });
    }
  }

  closeDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
      this.dialogRef = null;
    }
  }
}