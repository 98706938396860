import { Component } from '@angular/core';
import { eAssetType, EnumeratedTypesRepositoryService, eOrderType, JurisdictionInfo, Order, OrderManagerService, OrderUIConfiguration, SelectOption, UIConfigurationService, ValidationProblem, ValidationService } from 'reg-hub-common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SteppedComponent } from 'projects/reg-hub-client/src/interfaces/stepped-component';
import { StepValidationState } from 'projects/reg-hub-client/src/interfaces/step';
import { BehaviorSubject } from 'rxjs';
import { SearchParameters } from 'dist/reg-hub-common/lib/interfaces/search-parameters/search-parameters';

@Component({
  selector: 'app-multi-search-criteria',
  templateUrl: './multi-search-criteria.component.html',
  styleUrls: ['./multi-search-criteria.component.css']
})
export class MultiSearchCriteriaComponent extends SteppedComponent {
  protected orderTypes = [eOrderType.assetSearch];
  protected searchForm!: FormGroup;
  protected loading = false;
  protected assetTypes: SelectOption[] = [];
  protected orderJurisdictions: string[] = [];
  protected configs: OrderUIConfiguration[] = [];

  protected serialNumberError$ = new BehaviorSubject<string | null | undefined>(null);
  protected searchFromDateError$ = new BehaviorSubject<string | null | undefined>(null);

  constructor(orderManager: OrderManagerService,
    validationService: ValidationService,
    private formBuilder: FormBuilder,
    private enumeratedTypesRepo: EnumeratedTypesRepositoryService) {
    super(orderManager, validationService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.searchForm = this.formBuilder.group({
      serialNumber: this.formBuilder.control(this.order.assets?.at(0)?.serialNumber)
    });
    this.loading = true;

    this.orderJurisdictions = this.order.searchParameters?.searchJurisdictions?.map(j => j.jurisdiction) ?? [];

    //Need to refactor. UI configs should return the jurisdiction they are, then we can get all jurisdiction asset types and
    //give distinct ones
    this.enumeratedTypesRepo.getAssetTypesAsSelectOptions(eOrderType.assetSearch, 'SK').subscribe(jurisdictionTypes => {
      this.assetTypes = jurisdictionTypes;
    });
  }

  protected onJurisdictionSelected(jurisdictions: JurisdictionInfo[]) {
    this.orderJurisdictions = jurisdictions.map(j => j.jurisdictionCode);
  }

  override onSaving(): void {
    const asset = this.order.assets?.at(0);

    if(asset) {
      asset.assetTypeID = eAssetType.None;
      asset.serialNumber = this.searchForm.get('serialNumber')?.value;
    }

    if(!this.order.searchParameters) {
      this.order.searchParameters = { } as SearchParameters;
    }

    this.order.searchParameters.searchJurisdictions = this.orderJurisdictions.map(j => {
      return { 
        id: this.order.searchParameters?.searchJurisdictions.find(sj => sj.jurisdiction == j)?.id, 
        jurisdiction: j, 
        searchParametersID: this.order.searchParameters?.id };
    })
  }

  public override getValidationState(errors: ValidationProblem[] | undefined, order: Order): StepValidationState {
    return !!order.assets?.at(0)?.serialNumber && (order.searchParameters?.searchJurisdictions.length ?? 0) > 0 ? StepValidationState.ValidationSuccess : StepValidationState.ValidationError;
  }
}
