<lib-validation-problems [saving$]="saving$" *ngIf="!printMode"></lib-validation-problems>

<div *ngIf="uiConfiguration.hasReferenceRegistrationInformation">
    <h3>Existing Registration Details</h3>
    <lib-existing-registration-details
    [uiConfiguration]="uiConfiguration.referenceRegistrationInformationUIConfiguration!">
    </lib-existing-registration-details>
</div>

<h3 *ngIf="uiConfiguration.hasRenewalDetails">{{order.orderType.name}} Details</h3>
<div class="flex-item-no-padding" *ngIf="uiConfiguration.hasRenewalDetails">
    <div class="margin-bottom-5">
        <div class="flex-container" *ngIf="uiConfiguration.lienDetailsConfiguration!.showQCFormTypeID">
            <span class="flex-item-3">Form Type:</span>
            <span class="flex-item-4" data-testid="lien-details-form-type-text">{{ order.lien?.qcFormTypeID }}</span>
        </div>
        <div *ngIf="(uiConfiguration.renewalDetailsConfiguration!.showTerm || uiConfiguration.renewalDetailsConfiguration!.showInfinityTerm) && (order.lien?.term || order.lien?.isInfiniteTerm)" class="flex-container">
            <span class="flex-item-3">Term:</span>
            <span class="flex-item-4" data-testid="lien-details-term-text">{{ order.lien?.isInfiniteTerm ? "INFINITY" : order.lien?.term }}</span>
        </div>
        <div *ngIf="uiConfiguration.renewalDetailsConfiguration!.showTerm && uiConfiguration.renewalDetailsConfiguration!.registryDefinedTerm" class="flex-container">
            <span class="flex-item-3">Term:</span>
            <span class="flex-item-4" data-testid="lien-details-term-text">{{ uiConfiguration.lienDetailsConfiguration!.registryDefinedTerm }}</span>
        </div>
        <div *ngIf="uiConfiguration.renewalDetailsConfiguration!.showExpiryDate && !order.lien?.term && !order.lien?.isInfiniteTerm" class="flex-container">
            <span class="flex-item-3">{{ getExpiryDateHeader() }}</span>
            <span class="flex-item-4" data-testid="lien-details-expiry-date-text">{{ order.lien?.expiryDate | localizedDate: 'yyyy-MM-dd' }}</span>
        </div>
    </div>
</div>
<lib-lien-results [uiConfiguration]="uiConfiguration.lienDetailsConfiguration!"
    [results]="order.lien?.registrationResults?.at(0)" *ngIf="isHistorical()"
    class="flex-item">
</lib-lien-results>