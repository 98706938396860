<div *ngIf="loading">
    <div class="flex-container-center margin-bottom-15">
        <mat-spinner></mat-spinner>
    </div>
    <div class="flex-container-center">
        Saving ...
    </div>
</div>

<div *ngIf="!loading && orders.length > 0">
    <!-- Orders table -->
    <table mat-table [dataSource]="orders" multiTemplateDataRows >
        <ng-container matColumnDef="criteria">
            <th mat-header-cell *matHeaderCellDef id="criteria"> Search Criteria
            </th>
            <td mat-cell *matCellDef="let order" [style.cursor]="hasErrors(order) || hasWarnings(order) ? 'pointer' : 'default'"> {{ order.resource.assets?.at(0)?.serialNumber }} </td>
        </ng-container>

        <ng-container matColumnDef="country">
            <th mat-header-cell *matHeaderCellDef id="country">Country
            </th>
            <td mat-cell *matCellDef="let order" [style.cursor]="hasErrors(order) || hasWarnings(order) ? 'pointer' : 'default'">
                {{ order.resource.country }}
            </td>
        </ng-container>

        <ng-container matColumnDef="jurisdiction">
            <th mat-header-cell *matHeaderCellDef id="jurisdiction">
                Jurisdiction
            </th>
            <td mat-cell *matCellDef="let order" [style.cursor]="hasErrors(order) || hasWarnings(order) ? 'pointer' : 'default'">
                {{ order.resource.displayJurisdiction }}
            <td>
        </ng-container>

        <ng-container matColumnDef="validation">
            <th mat-header-cell *matHeaderCellDef id="validation">
                Validation
            </th>
            <td mat-cell *matCellDef="let order" [style.cursor]="hasErrors(order) || hasWarnings(order) ? 'pointer' : 'default'">
                <mat-icon class="margin-left-5 icon-error" *ngIf="hasErrors(order)">error</mat-icon>
                <mat-icon class="margin-left-5 icon-warning" *ngIf="hasWarnings(order)">warning</mat-icon>
                <mat-icon class="margin-left-5 icon-success"
                    *ngIf="!hasErrors(order) && !hasWarnings(order)">check_circle</mat-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let order" [attr.colspan]="displayedColumns.length">
                <div class="expanded-entity-detail"
                    [@detailExpand]="order === _expandedOrder ? 'expanded' : 'collapsed'">
                    <div class="expanded-entity-information">
                        <lib-validation-problems [useStaticValidation]="true"
                            [validation]="order.validation"></lib-validation-problems>
                    </div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let order; columns: displayedColumns;" class="expandable-table-row"
            [class.example-expanded-row]="_expandedOrder === order" (click)="onRowClick(order)">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail'] " class="expandable-detail-row">
        </tr>

    </table>
    <!-- End of orders table-->
</div>

<lib-placeholder iconName="error" iconClass="icon-error" message="No jurisdictions selected" [condition]="!loading && orders.length === 0"></lib-placeholder>
