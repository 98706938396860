import { Component } from '@angular/core';
import { Constants } from 'projects/reg-hub-client/src/constants';

@Component({
  selector: 'app-confirm-submit-and-return',
  templateUrl: './confirm-submit-and-return.component.html',
  styleUrls: ['./confirm-submit-and-return.component.css']
})
export class ConfirmSubmitAndReturnComponent {
  protected shouldReturnToBusinessSearchResults: boolean = true;

  protected getDestination() {
    return this.shouldReturnToBusinessSearchResults ? Constants.returnToOriginatingOrder : Constants.stayOnNewOrder;
  }
}