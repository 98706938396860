<mat-card *ngIf="orderFees">
  <mat-card-header>
    <span class="flex-container-space-between">
      <mat-card-title>Order Summary</mat-card-title>
      <lib-disclosure-indicator [isExpanded]="!collapsed" color="white"
        (click)="onCollapse()"></lib-disclosure-indicator>
    </span>
  </mat-card-header>
  <mat-card-content>
    <div [@slideDown]="collapsed ? 'hidden' : 'visible'">
      <table mat-table [dataSource]="orderFees" class="mat-elevation-z0">
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef> Description </th>
          <td mat-cell *matCellDef="let fee"> <span *ngIf="fee.id == emptyGuid" class="expected">*
            </span>{{getDescription(fee)}} </td>
        </ng-container>

        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef> Amount </th>
          <td mat-cell *matCellDef="let fee"> {{(fee.amount) | currency}} </td>
        </ng-container>

        <ng-container matColumnDef="tax">
          <th mat-header-cell *matHeaderCellDef> Tax </th>
          <td mat-cell *matCellDef="let fee"> {{(fee.gst + fee.pst + fee.hst) | currency}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <div class="always-showing">
      <span *ngIf="!isComplete" class="expected total">*</span><span class="total">Total: {{ totalCost | currency
        }}</span>
    </div>
    <div class="always-showing">
      <p *ngIf="!isComplete" class="expected">*Expected</p>
    </div>
  </mat-card-content>
</mat-card>