<form [formGroup]="lienDetails">
    <div class="padding">
        <div class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Registration Number</mat-label>
                    <input matInput data-testid="registration-number-field" placeholder="Registration Number"
                        [formControlName]="'registrationNumber'" libReplaceCharacter>
                </mat-form-field>
                <lib-error-bubble [control]="lienDetails.controls['registrationNumber']"
                    [message]="registrationNumberError$"></lib-error-bubble>
            </div>
        </div>

        <div  class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="form-field flex-item" appearance="outline">
                    <mat-label>Registration Date</mat-label>
                    <input matInput [matDatepicker]="registrationDate" data-testid="registration-date-field" formControlName="registrationDate"
                        placeholder="YYYY-MM-DD" >
                    <mat-datepicker-toggle matSuffix [for]="registrationDate"></mat-datepicker-toggle>
                    <mat-datepicker #registrationDate></mat-datepicker>
                </mat-form-field>
                <lib-error-bubble [control]="lienDetails.get('registrationDate')!"
                    [message]="registrationDateError$"></lib-error-bubble>
            </div>
        </div>

        <app-term [uiConfiguration]="uiConfiguration.lienDetailsConfiguration!" [errors$]="observableErrors$"
            [order]="order" [isDisabled]="isDisabled"></app-term>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showAmount" class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Amount</mat-label>
                    <input matInput data-testid="amount-field" placeholder="Amount" type="number" [formControlName]="'amount'"
                        >
                </mat-form-field>
                <lib-error-bubble [control]="lienDetails.controls['amount']"
                    [message]="amountError$"></lib-error-bubble>
            </div>
        </div>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showSumOfHypothec" class="flex-container">
            <mat-form-field *ngIf="this.isRHa" class="flex-item form-field" appearance="outline">
                <mat-label>Sum of Hypothec</mat-label>
                <textarea matInput data-testid="sum-of-hypothetic-field" placeholder="Amount" [formControlName]="'sumOfHypothec'"
                    ></textarea>
            </mat-form-field>
        </div>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showAdditionalInformation" class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Additional Information</mat-label>
                    <textarea matInput data-testid="additional-information-field" [formControlName]="'additionalInformation'"
                        placeholder="Additional information" ></textarea>
                </mat-form-field>
                <lib-error-bubble [control]="lienDetails.controls['additionalInformation']"
                    [message]="additionalInformationError$"></lib-error-bubble>
            </div>
        </div>
    </div>
</form>

<div *ngIf="uiConfiguration.lienDetailsConfiguration?.showSigningCountry ||
            uiConfiguration.lienDetailsConfiguration?.showSigningJurisdiction ||
            uiConfiguration.lienDetailsConfiguration?.showSigningCity ||
            uiConfiguration.lienDetailsConfiguration?.showSigningDate" class="padding">
    <lib-signing-location [errors$]="observableErrors$" [order]="order" [isDisabled]="isDisabled"
        (signingLocationFormValueChangedEvent)="handleSigningLocationFormValueChangedEvent()"></lib-signing-location>
</div>