import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OnGroupDataMessageArgs, WebPubSubClient } from '@azure/web-pubsub-client';
import { Observable, ReplaySubject, Subject, from } from 'rxjs';
import { HubService, NotificationService, Order } from 'reg-hub-common';
import { Constants } from '../../../../constants';
import { EnvironmentUrlService } from '../../environment-url/environment-url.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class OrdersHubService extends HubService implements OnDestroy {
  private client: WebPubSubClient | undefined;
  private messagesSubject = new ReplaySubject<any>(1);  // stores last message, can be Subject if you prefer
  public messages$: Observable<any> = this.messagesSubject.asObservable();

  private completedOrder = 'CompletedOrder';

  // Subjects for order events
  private completedOrderSubject = new Subject<Order>();
  public completedOrder$ = this.completedOrderSubject.asObservable();

  private joinedGroups = new Set<string>();
  private isConnected = false;

  constructor(private http: HttpClient,
    private environmentUrl: EnvironmentUrlService,
    private notificationService: NotificationService,
    private router: Router) { super(); }

  connect(): void {
    if (!this.isConnected) {
      this.http.get<any>(`${this.environmentUrl.urlAddress}${Constants.ordersHubNegotiateUrl}`)
        .subscribe({
          next: response => this.completeConnection(response.url),
          error: err => console.error("Error negotiating URL:", err)
        });
    }
  }

  private completeConnection(url: string): void {
    this.client = new WebPubSubClient({
      getClientAccessUrl: async () => url
    });

    // Set up event handlers
    this.client.on("connected", (e) => {
      this.isConnected = true;
    });

    // handle messages to a group when received
    this.client.on("group-message", (messageEvent) => {
      this.handleGroupMessageReceived(messageEvent);
    });

    // handle disconnection from the hub
    this.client.on("disconnected", () => {
      console.warn("Disconnected from Web PubSub");
      this.isConnected = false;
    });

    // Start the connection
    from(this.client.start()).subscribe({
      next: () => {
        console.log('Client started');
      },
      error: err => console.error('Error starting client', err)
    });
  }

  private joinGroupIfNotJoined(groupName: string) {
    if (!this.joinedGroups.has(groupName)) {
      this.client?.joinGroup(groupName)
        .then(() => {
          this.joinedGroups.add(groupName);
        })
    }
  }

  joinOrderGroup(order: Order) {
    this.joinGroupIfNotJoined(`${this.completedOrder}-${order.id}`);
  }

  private leaveGroupIfJoined(groupName: string) {
    if (this.joinedGroups.has(groupName)) {
      this.client?.leaveGroup(groupName)
        .then(() => {
          this.joinedGroups.delete(groupName);
        })
    }
  }

  leaveOrderGroup(order: Order) {
    this.leaveGroupIfJoined(`${this.completedOrder}-${order.id}`);
  }

  handleGroupMessageReceived(messageEvent: OnGroupDataMessageArgs) {
    if (messageEvent.message.dataType === "text") {
      const rawOrder = JSON.parse(messageEvent.message.data as string, this.toCamelCase);
      const order = rawOrder as Order;
      
      const currentUrl = this.router.url; 
      const completedOrderUrl = `/orders/${order.id}`;
      this.completedOrderSubject.next(order);

      if (currentUrl !== completedOrderUrl) {
        this.notificationService.addNotification(
          "Order Completed", 
          `A recently requested order has completed.`, 
          order.id
        );
      }

      this.leaveOrderGroup(order);

      this.messagesSubject.next(order);
    }
  }

  ngOnDestroy() {
    // Stop the connection if the service is destroyed (e.g., in certain testing scenarios)
    if (this.client) {
      this.client.stop();
    }
  }
}