import { QCDischargeComponent } from "projects/reg-hub-client/src/app/orders/qc-discharge/qc-discharge.component";
import { Step, StepValidationState } from "../../../../../interfaces/step";
import { Stepper } from "../../../../../interfaces/stepper";
import { DischargeReviewComponent } from "projects/reg-hub-client/src/app/orders/discharge-review/discharge-review.component";

export class QCDischargeStepper implements Stepper {

    steps: Step[];

    constructor() {
        this.steps = [
            { title: "Details", content: QCDischargeComponent, validationState: StepValidationState.DefaultState },
            { title: "Review", content: DischargeReviewComponent, validationState: StepValidationState.DefaultState }
        ]
    }
}