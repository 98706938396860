<div class="padding">
    <h1 class="left-box">System Events</h1>
    <mat-card>
        <mat-card-content>
            <app-system-events-history-filter></app-system-events-history-filter>
            <div class="table-container padding-top">
                <table mat-table matSort [dataSource]="this.repo.resource$" multiTemplateDataRows
                    class="mat-elevation-z0" (matSortChange)="sort($event)"
                    [class.table-loading]="this.repo.loading$ | async">

                    <!-- Event Type -->
                    <ng-container matColumnDef="systemEventTypeID">
                        <th mat-header-cell *matHeaderCellDef> Event Type </th>
                        <td mat-cell *matCellDef="let sysEvent"> {{sysEvent.systemEventType.name}} </td>
                    </ng-container>

                    <!-- Message -->
                    <ng-container matColumnDef="message">
                        <th mat-header-cell *matHeaderCellDef> Message </th>
                        <td mat-cell *matCellDef="let sysEvent"> {{sysEvent.message}} </td>
                    </ng-container>

                    <!-- Start Date -->
                    <ng-container matColumnDef="startDate">
                        <th mat-header-cell *matHeaderCellDef> Start </th>
                        <td mat-cell *matCellDef="let sysEvent"> {{sysEvent.startDate | utcToLocalDate}} </td>
                    </ng-container>

                    <!-- End Date -->
                    <ng-container matColumnDef="endDate">
                        <th mat-header-cell *matHeaderCellDef> End </th>
                        <td mat-cell *matCellDef="let sysEvent"> {{sysEvent.endDate | utcToLocalDate}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                    </tr>
                </table>
            </div>
        </mat-card-content>
        <mat-paginator 
        *ngIf="(this.repo.resourceCount$ | async) ?? 0 > 0"
        [pageSize]="this.repo.pageSize$ | async" 
        [length]="this.repo.resourceCount$ | async"
        [pageIndex]="this.repo.pageNum$ | async"
        [pageSizeOptions]="[10, 25, 50]" 
        (page)="page($event)"></mat-paginator>
    </mat-card>
</div>