<h3>{{ isFormEnabled ? "Enter Report Criteria" : "Report Criteria Review" }}</h3>
<div class="padding">
    <form [formGroup]="searchCriteriaDetails">
        <div class="flex-container" *ngIf="order.businessReportCriteria?.businessName || this.uiConfiguration.businessReportDetailsConfiguration?.showBusinessName;">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Exact Name as it is Registered</mat-label>
                    <input matInput [formControlName]="'name'" data-testid="business-name-field"
                        placeholder="Exact Name as it is Registered"
                        [value]="order.businessReportCriteria?.businessName ?? ''">
                </mat-form-field>
                <lib-error-bubble [control]="searchCriteriaDetails.get('name')!"
                [message]="nameError$"></lib-error-bubble>
            </div>
        </div>
        <div class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>{{this.uiConfiguration.businessReportDetailsConfiguration?.businessNumberHint!}}</mat-label>
                    <input matInput [formControlName]="'number'" data-testid="business-number-field" [placeholder]="this.uiConfiguration.businessReportDetailsConfiguration?.businessNumberHint ?? ''">
                </mat-form-field>
                <lib-error-bubble [control]="searchCriteriaDetails.get('number')!"
                    [message]="numberError$"></lib-error-bubble>
            </div>
        </div>
    </form>
</div>