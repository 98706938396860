<div class="account-container padding">
    <h1 class="left-box">My Account</h1>
  
    <!-- Account Details Section -->
    <div class="section">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Account Details</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="detail-row">
            <span class="label">Username:</span>
            <span class="value">{{ username }}</span>
          </div>
          <div class="actions">
            <button mat-raised-button color="primary" (click)="changePassword()">
              Change Password
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  
    <!-- Two-Factor Authentication Section -->
    <div class="section">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Two-Factor Authentication</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="actions">
            <button mat-raised-button color="accent" (click)="viewAuthenticator()">
              View Authenticator Setup
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>