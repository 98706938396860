import { Component, Input } from '@angular/core';
import { AssetUIConfiguration, Order } from 'reg-hub-common';

@Component({
  selector: 'app-asset-list',
  templateUrl: './asset-list.component.html',
  styleUrls: ['./asset-list.component.css']
})
export class AssetListComponent {
  @Input({required: true}) order!: Order;
  @Input({required: true}) uiConfiguration!: AssetUIConfiguration;

  displayedColumns: string[] = ['serial-number', 'asset-type', 'make', 'model', 'year'];

  ngOnInit(): void {
    if (this.uiConfiguration.showColor) {
      this.displayedColumns = this.displayedColumns.concat('colour');
    }
  }

  getAssets() {
    return this.order.assets?.filter(asset => asset.movedToGeneralCollateral === false) ?? [];
  }
}
