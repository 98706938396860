import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationSkipped, Router, Event, RouterEvent } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Order, OrderManagerService, OrderRepositoryService, OrderHistoryComponent, eOrderType, eOrderTypeGroup, OrderMessagesComponent, UIConfigurationService, OrderUIConfiguration, eOrderStatusType } from 'reg-hub-common';
import { filter, map, Subject, Subscription, takeUntil } from 'rxjs';
import { OrderValidationLogsComponent } from '../order-validation-logs/order-validation-logs.component';
import { PrintDataService } from '../../services/print-data/print-data.service';
import { OrdersHubService } from '../../services/hubs/orders-hub/orders-hub.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit, OnDestroy {
  protected uiConfiguration!: OrderUIConfiguration;
  private unsubscribe$ = new Subject<void>();
  private eventSub$!: Subscription;

  orderId: string = "";
  order: Order = {
    referenceNumber: "",
    country: "",
    jurisdiction: "",
    orderTypeID: eOrderType.notSet
  } as Order;

  isLoadingOrder: boolean = false;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected orderRepo: OrderRepositoryService,
    protected dialog: MatDialog,
    protected orderManager: OrderManagerService,
    protected uiConfigurationService: UIConfigurationService,
    protected ordersHub: OrdersHubService,
    protected printDataService: PrintDataService
  ) { }

  ngOnInit(): void {
    this.ordersHub.connect();

    this.orderManager.currentOrder
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(order => {
        this.order = order;
      });

    this.activatedRoute.paramMap.subscribe(params => {
      this.orderId = params.get('orderID')!;
      this.getOrder(this.orderId);
    });

    this.eventSub$ = this.router.events
      .pipe(
        filter(event => event instanceof NavigationSkipped),
        map(event => event as NavigationSkipped))
      .subscribe((event: NavigationSkipped) => {
        if (event.url.includes(this.orderId)) {
          this.getOrder(this.orderId);
        }
      });

    // Subscribe to completed order events
    this.ordersHub.completedOrder$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(completedOrder => {
        if (completedOrder.id === this.orderId) {
          this.order = completedOrder as Order;
          this.order.orderStatusTypeID = "Complete";
          this.orderManager.updateOrder(this.order);
        }
      });
  }

  ngOnDestroy(): void {
    // Signal all subscriptions to complete
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.eventSub$.unsubscribe();
  }

  getUIConfiguration(order: Order) {
    this.uiConfigurationService.getOrderUIConfiguration(order).subscribe(config => {
      this.uiConfiguration = config;
      this.isLoadingOrder = false;
    });
  }

  openOrderHistory() {
    this.dialog.open(OrderHistoryComponent, {
      data: this.order,
      minWidth: '800px'
    });
  }

  openValidationLogs() {
    this.dialog.open(OrderValidationLogsComponent, {
      data: this.order,
      minWidth: '800px'
    });
  }

  openOrderMessages() {
    this.dialog.open(OrderMessagesComponent, {
      data: { order: this.order, customer: true },
      minWidth: '800px'
    });
  }

  messageCount() {
    let length = this.order?.orderMessages?.filter((u) => u.isCustomerVisible && !u.isReadByCustomer).length ?? 0;
    return length > 0 ? length.toString() : "";
  }

  private getOrder = (orderID: string) => {
    // this will call api/v1/orders/id
    this.isLoadingOrder = true;
    this.orderRepo.getOrder('orders', orderID)
      .subscribe({
        next: (order: any) => {

          if (order.orderStatusTypeID.toLowerCase() == "draft" ||
            order.orderStatusTypeID.toLowerCase() == "invalid" ||
            order.orderStatusTypeID.toLowerCase() == "historicaldraft") {
            this.router.navigate([
              this.getDraftRedirectUrl(order.orderTypeID),
              order.id
            ]);
          }
          this.order = order as Order;
          this.orderManager.updateOrder(this.order);
          this.getUIConfiguration(this.order);
        },
        error: (error: any) => {
          // Handle error
          console.error('Error:', error);
        }
      });
  };

  private getDraftRedirectUrl(orderTypeID: eOrderType | string) {
    switch (orderTypeID) {
      case eOrderType.amendment:
      case "Amendment":
        return "new-registration";
      case eOrderType.assetSearch:
      case "AssetSearch":
      case eOrderType.assetMultiSearch:
        return "new-search";
      case eOrderType.debtorSearch:
      case eOrderType.certifiedDebtorSearch:
      case "DebtorSearch":
        return "new-search";
      case eOrderType.discharge:
      case "Discharge":
        return "new-registration";
      case eOrderType.lien:
      case "Lien":
        return "new-registration";
      case eOrderType.notSet:
        return "new-registration";
      case eOrderType.renewal:
      case "Renewal":
        return "new-registration";
      case eOrderType.repairLien:
      case "RepairLien":
        return "new-registration";
      case eOrderType.basicDischarge:
      case "BasicDischarge":
        return "new-registration";
      case eOrderType.basicRenewal:
      case "BasicRenewal":
        return "new-registration";
      case eOrderType.businessSearch:
      case "BusinessSearch":
        return "new-business-search";
      case eOrderType.businessReport:
      case "BusinessReport":
        return "new-business-search";
      default:
        return "";
    }
  }

  isActionable(): boolean {
    if (!(this.uiConfiguration.hasActions ?? false) ||
      !this.uiConfiguration.actionUIConfiguration) {
      return false;
    }

    if (this.order.orderTypeID === eOrderType.businessReport) {
      return this.order.orderStatusTypeID === eOrderStatusType.complete &&
        (this.uiConfiguration.actionUIConfiguration.hasUpdate ?? false);
    }

    return this.order.isLastOrder === true;
  }

  print() {
    this.printDataService.printOrder();
  }

  showPrintButton() {
    return this.printDataService.shouldShowPrintOrderButton(this.order);
  }
}
