<div *ngIf="loading; else orderDetails" class="loading-spinner-overlay">
    <div class="loading-spinner-overlay">
        <mat-spinner></mat-spinner>
    </div>
</div>
<ng-template #orderDetails>
    <div class="padding full-height">
        <div class="flex-container-vertical-center">
            <h1 class="margin-sides-8" data-testid="base-order-order-type">{{ orderTypeName }}</h1>
            <span class="spacer"></span>

            <!-- Lien Result Document(s) -->
            <div *ngFor="let result of order.lien?.registrationResults ?? []" class="margin-sides-8">
                <lib-document-download *ngIf="!isDischarge()" [documentID]="result.document?.id" [documentName]="'Verification Statement'">
                </lib-document-download>
            </div>

            <!-- Discharge Result Document(s) -->
            <div *ngIf="isDischarge() && order.discharge?.document" class="margin-sides-8">
                <span class="spacer"></span>
                <lib-document-download [documentID]="order.discharge!.document!.id"
                    [documentName]="'Verification Statement'"></lib-document-download>
            </div>

            <!-- Search Result Document(s) -->
            <lib-download-search-documents *ngIf="this.order.searchResult" class="margin-sides-8" data-testid="base-order-search-results-button" [order]="order"></lib-download-search-documents>
        </div>

        <div class="horizontal-flex-container">
            <!-- Order Type Card -->
            <mat-card class="flex-item card-margin">
                <mat-card-content>
                    <div class="flex-container">
                        <span class="flex-item-2">Reference Number:</span>
                        <span class="flex-item-2" data-testid="base-order-reference-number-text">{{ order.referenceNumber }}</span>
                    </div>

                    <div class="flex-container">
                        <span class="flex-item-2">Country:</span>
                        <span class="flex-item-2" data-testid="base-order-country-text">{{ order.country | fullCountryName }}</span>
                    </div>

                    <div class="flex-container">
                        <span class="flex-item-2">Jurisdiction:</span>
                        <span class="flex-item-2" data-testid="base-order-jurisdiction-text">{{ order.jurisdiction | fullJurisdictionName:order.country }}</span>
                    </div>

                    <div class="flex-container" *ngIf="order.lien?.qcFormTypeID">
                        <span class="flex-item-2">Form:</span>
                        <span class="flex-item-2" data-testid="base-order-qc-form-text">{{ order.lien!.qcFormTypeID }}</span>
                    </div>
                </mat-card-content>
            </mat-card>

            <mat-card class="flex-item card-margin">
                <mat-card-content>
                    <div class="flex-container-center">
                        <span class="flex-item-2">Status:</span>
                        <span class="flex-item-2 flex-container-vertical-center">
                            <lib-order-status [order]="order"></lib-order-status>
                            <span data-testid="status-text"><b>{{ order.orderStatusTypeID }}</b></span>
                        </span>
                    </div>

                    <div class="flex-container">
                        <span class="flex-item-2">Requested By:</span>
                        <span class="flex-item-2" data-testid="requested-by-text">{{ requestingUser }}</span>
                    </div>

                    <div class="flex-container">
                        <span class="flex-item-2">Date Requested:</span>
                        <span class="flex-item-2" data-testid="requested-date-text">{{ order.dateRequested | localizedDate: 'yyyy-MM-dd'}}</span>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>

        <!-- Search Criteria Card -->
        <mat-card class="card-margin" *ngIf="isSearchOrder()">
            <mat-card-content>
                <lib-order-search-criteria></lib-order-search-criteria>
            </mat-card-content>
        </mat-card>

        <!-- Multi search -->
        <mat-card class="card-margin" *ngIf="isMultiSearchOrder()">
            <h2>Orders</h2>
            <mat-card-content>
                <lib-multi-search-orders [orderUpdate$]="this.ordersHub.completedOrder$"></lib-multi-search-orders>
            </mat-card-content>
        </mat-card>

        <mat-card class="card-margin" *ngIf="uiConfiguration?.hasBusinessSearchCriteria">
            <mat-card-content>
                <lib-order-business-search-criteria></lib-order-business-search-criteria>
            </mat-card-content>
        </mat-card>

        <mat-card class="card-margin" *ngIf="uiConfiguration?.hasBusinessReportCriteria">
            <mat-card-content>
                <lib-order-business-report-criteria></lib-order-business-report-criteria>
            </mat-card-content>
        </mat-card>

        <!-- Existing Registration Details Card -->
        <mat-card class="card-margin" *ngIf="uiConfiguration?.hasReferenceRegistrationInformation">
            <h2>Existing Registration Details</h2>
            <mat-card-content>
                <lib-existing-registration-details
                    [uiConfiguration]="uiConfiguration!.referenceRegistrationInformationUIConfiguration!">
                </lib-existing-registration-details>
            </mat-card-content>
        </mat-card>

        <div class="horizontal-flex-container" *ngIf="uiConfiguration.hasRenewalDetails && order?.lien">
            <!-- Renewal Details Card -->
            <mat-card class="flex-item-4 card-margin">
                <h2>Renewal Details</h2>
                <mat-card-content>
                    <div *ngIf="isRenewal()">
                        <div *ngFor="let result of order.lien?.registrationResults ?? []">
                            <h4 *ngIf="uiConfiguration?.amendmentConfiguration?.hasMultipleForms" class="no-margin">
                                {{ result.registryForm?.formFull ?? '' }} Results
                            </h4>
                            <lib-lien-results [uiConfiguration]="uiConfiguration.lienDetailsConfiguration!"
                                [results]="result">
                            </lib-lien-results>
                            <br />
                        </div>
                    </div>
                    <lib-lien-details *ngIf="uiConfiguration.hasRenewalDetails"
                        [uiConfiguration]="uiConfiguration.renewalDetailsConfiguration!">
                    </lib-lien-details>
                </mat-card-content>
            </mat-card>

            <mat-card class="flex-item-2 card-margin" *ngIf="showLifetime()">
                <mat-card-content>
                    <lib-lien-lifetime></lib-lien-lifetime>
                </mat-card-content>
            </mat-card>
        </div>

        <div class="horizontal-flex-container" *ngIf="uiConfiguration.amendmentConfiguration && order?.lien">
            <!-- Renewal Details Card -->
            <mat-card class="flex-item-4 card-margin">
                <h2>Amendment Details</h2>
                <mat-card-content>
                    <div *ngIf="(order.lien?.registrationResults?.length ?? 0) > 0">
                        <div *ngFor="let result of order.lien?.registrationResults ?? []">
                            <h4 *ngIf="uiConfiguration?.amendmentConfiguration?.hasMultipleForms" class="no-margin">
                                {{ result.registryForm?.formFull ?? '' }} Results
                            </h4>
                            <lib-lien-results [uiConfiguration]="uiConfiguration.lienDetailsConfiguration!"
                                [results]="result">
                            </lib-lien-results>
                            <br />
                        </div>
                    </div>
                    <div *ngIf="(order.lien?.registrationResults?.length ?? 0) == 0">
                        <lib-lien-results [uiConfiguration]="uiConfiguration.lienDetailsConfiguration!">
                        </lib-lien-results>
                    </div>
                </mat-card-content>
            </mat-card>

            <mat-card class="flex-item-2 card-margin" *ngIf="showLifetime()">
                <mat-card-content>
                    <lib-lien-lifetime></lib-lien-lifetime>
                </mat-card-content>
            </mat-card>
        </div>

        <div class="horizontal-flex-container">
            <!-- Discharge Details Card -->
            <mat-card class="flex-item-4 card-margin" *ngIf="uiConfiguration.hasDischargeDetails">
                <h2>Discharge Details</h2>
                <mat-card-content>
                    <lib-discharge-details [uiConfiguration]="uiConfiguration.dischargeDetailsConfiguration!" [order]="order" class="flex-item"></lib-discharge-details>
                </mat-card-content>
            </mat-card>
        </div>

        <div class="horizontal-flex-container">
            <!-- Lien Details Card -->
            <mat-card class="flex-item-4 card-margin" *ngIf="uiConfiguration.hasLienDetails && order?.lien">
                <h2>Lien Details</h2>
                <mat-card-content>
                    <div *ngIf="!isDischarge() && !isRenewal() && !isAmendment()">
                        <div *ngFor="let result of order.lien?.registrationResults ?? []">
                            <br />
                            <h4 *ngIf="uiConfiguration?.amendmentConfiguration?.hasMultipleForms" class="no-margin">
                                {{ result.registryForm?.formFull ?? '' }} Results
                            </h4>
                            <lib-lien-results [uiConfiguration]="uiConfiguration.lienDetailsConfiguration!"
                                [results]="result">
                            </lib-lien-results>
                            <br />
                        </div>
                    </div>
                    <lib-lien-details *ngIf="uiConfiguration.hasLienDetails"
                        [uiConfiguration]="uiConfiguration.lienDetailsConfiguration!">
                    </lib-lien-details>
                </mat-card-content>
            </mat-card>

            <mat-card class="flex-item-2 card-margin" *ngIf="showLifetime() && !isRenewal() && !isAmendment()">
                <mat-card-content>
                    <lib-lien-lifetime></lib-lien-lifetime>
                </mat-card-content>
            </mat-card>
        </div>

        <!-- Secured Parties Card -->
        <mat-card class="card-margin" *ngIf="!isSearchOrder() && uiConfiguration?.hasSecuredParties">
            <h2>Secured Parties</h2>
            <mat-card-content data-testid="secured-parties-card">
                <lib-parties-list [title]="securedPartiesTitle" [observableParties$]="securedParties"
                    [isEditable]="false" [uiConfiguration]="uiConfiguration!.securedPartiesConfiguration!"
                    class="flex-item-no-padding">
                </lib-parties-list>
            </mat-card-content>
        </mat-card>

        <!-- Debtors Card -->
        <mat-card class="card-margin" *ngIf="!isSearchOrder() && uiConfiguration?.hasDebtors">
            <h2>Debtors</h2>
            <mat-card-content data-testid="debtors-parties-card">
                <lib-parties-list [title]="debtorsTitle" [observableParties$]="debtors" [isEditable]="false"
                    [uiConfiguration]="uiConfiguration!.debtorsConfiguration!" class="flex-item-no-padding">
                </lib-parties-list>
            </mat-card-content>
        </mat-card>

        <!-- Dealers Card -->
        <mat-card class="card-margin" *ngIf="uiConfiguration?.hasDealers">
            <h2>Dealers</h2>
            <mat-card-content data-testid="dealers-parties-card">
                <lib-parties-list [title]="dealersTitle" [observableParties$]="dealers" [isEditable]="false"
                    [uiConfiguration]="uiConfiguration!.dealersConfiguration!" class="flex-item-no-padding">
                </lib-parties-list>
            </mat-card-content>
        </mat-card>

        <!-- General Collateral Card -->
        <mat-card class="card-margin" *ngIf="uiConfiguration!.collateralConfiguration?.hasGeneralCollateral && order?.lien" 
            data-testid="general-collateral-card">
            <h2>General Collateral</h2>
            <mat-card-content>
                <lib-general-collateral
                    [uiConfiguration]="uiConfiguration.collateralConfiguration?.generalCollateralConfiguration">
                </lib-general-collateral>
            </mat-card-content>
        </mat-card>

        <!-- Assets Card -->
        <mat-card class="card-margin" *ngIf="uiConfiguration!.collateralConfiguration?.hasAssets && order.assets?.length ?? 0 > 0"
            data-testid="general-collateral-card">
            <h2>Assets</h2>
            <mat-card-content>
                <app-asset-list [order]="order"
                    [uiConfiguration]="uiConfiguration!.collateralConfiguration!.assetConfiguration!" class="flex-item">
                </app-asset-list>
            </mat-card-content>
        </mat-card>

        <!-- Registering Agent Card -->
        <mat-card class="card-margin" *ngIf="uiConfiguration?.hasRegisteringAgents" data-testid="registering-agent-parties-card">
            <h2>Registering Agent</h2>
            <mat-card-content>
                <lib-parties-list [title]="registeringAgentsTitle" [observableParties$]="registeringAgents"
                    [isEditable]="false" [uiConfiguration]="uiConfiguration!.registeringAgentsConfiguration!"
                    class="flex-item-no-padding">
                </lib-parties-list>
            </mat-card-content>
        </mat-card>

        <mat-card class="card-margin min-height-200" *ngIf="uiConfiguration?.hasBusinessSearchCriteria"
            data-testid="business-search-results-card">
            <h2>Search Results</h2>
            <mat-card-content>
                <lib-business-search-results></lib-business-search-results>
            </mat-card-content>
        </mat-card>

    </div>
</ng-template>