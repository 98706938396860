<div class="padding">
    <div class="flex-container" *ngIf="!isLoadingOrder">
        <div class="flex-item">
            <div class="margin-sides-15">
                <mat-card>
                    <mat-card-content>
                        <mat-horizontal-stepper #orderStepper [linear]="false">
                            <mat-step label="Order Info" completed="false" state="edit">
                                <form #form="ngForm">
                                    <div class="padding">
                                        <form [formGroup]="baseOrderDetails" class="padding">
                                            <div class="flex-container"
                                                *ngIf="orderTypeGroupIDs[0] == ppsaRegistrationOrderTypeGroup">
                                                <mat-checkbox class="flex-item" [(ngModel)]="isHistorical"
                                                    [ngModelOptions]="{standalone: true}" [disabled]="isBasic"
                                                    data-testid="historical-checkbox">
                                                    Historical
                                                </mat-checkbox>
                                            </div>
                                            <app-static-warnings [showHistoricalWarning]="isHistorical"
                                                [showBasicWarning]="isBasic"></app-static-warnings>
                                            <div class="flex-container">
                                                <mat-form-field class="flex-item form-field" appearance="outline">
                                                    <mat-label>Reference Number</mat-label>
                                                    <input matInput [formControlName]="'referenceNumber'"
                                                        placeholder="Reference Number"
                                                        data-testid="reference-number-field">
                                                </mat-form-field>
                                            </div>
                                            <div class="flex-container">
                                                <mat-form-field class="flex-item form-field" appearance="outline">
                                                    <mat-label>Order Type</mat-label>
                                                    <mat-select [formControlName]="'orderTypeID'"
                                                        (selectionChange)="onOrderTypeSelectionChange()" data-testid="order-type-dropdown">
                                                        <mat-option *ngFor="let orderType of orderTypes"
                                                            [value]="orderType.value"
                                                            [attr.data-testid]="'order-type-option-' + orderType.label.replaceAll(' ', '-').toLowerCase()">
                                                            {{orderType.label}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <mat-form-field *ngIf="selectedOrderType && shouldShowJurisdiction" class="flex-item form-field"
                                                    appearance="outline">
                                                    <mat-label>Jurisdiction</mat-label>
                                                    <mat-select [formControlName]="'jurisdiction'" 
                                                        data-testid="jurisdiction-dropdown"
                                                        (selectionChange)="handleJurisdictionChangeEvent()">
                                                        <mat-option *ngFor="let jurisdiction of jurisdictions"
                                                            [value]="jurisdiction.jurisdictionCode"
                                                            [attr.data-testid]="'jurisdiction-option-' + jurisdiction.jurisdictionCode.toLowerCase()">
                                                            {{jurisdiction.jurisdictionFullName}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <div form formGroupName="searchParameters" class="form-field flex-item" *ngIf="selectedOrderType && shouldShowJurisdictionMulti">
                                                    <mat-form-field appearance="outline" class="flex-container">
                                                        <mat-label>Jurisdictions</mat-label>
                                                        <mat-select formControlName="searchJurisdictions" placeholder="Jurisdictions" multiple="true"
                                                            data-testid="jurisdictions-dropdown">
                                                            <mat-option *ngFor="let jurisdiction of jurisdictions" [value]="jurisdiction.jurisdictionCode"
                                                                [attr.data-testid]="'jurisdictions-option-' + jurisdiction.jurisdictionCode.toString().toLowerCase()">
                                                                {{ jurisdiction.jurisdictionFullName }}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </div>

                                            <form formGroupName="lien" *ngIf="orderIsQC && selectedOrderType == 'Lien'">
                                                <div class="flex-container">
                                                    <mat-form-field class="flex-item form-field" appearance="outline">
                                                        <mat-label>Form Type</mat-label>
                                                        <mat-select formControlName="qcFormTypeID"
                                                            data-testid="qcform-dropdown">
                                                            <mat-option *ngFor="let qcFormType of qcFormTypes"
                                                                [value]="qcFormType.value"
                                                                [attr.data-testid]="'form-type-option-' + qcFormType.label.split(' ')[0].toLowerCase()">
                                                                {{qcFormType.label}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </form>
                                        </form>
                                    </div>
                                </form>
                                <!-- Navigation buttons -->
                                <div class="flex-container padding-top">
                                    <span class="spacer"></span>
                                    <span class="spacer"></span>
                                    <span class="spacer"></span>
                                    <span class="spacer"></span>
                                    <span class="spacer"></span>

                                    <button mat-raised-button (click)="saveContents()" color="primary"
                                        class="apply-padding flex-item form-field" aria-label="Next step button"
                                        data-testid="order-info-next-button">
                                        Next
                                    </button>
                                </div>
                            </mat-step>

                            <ng-template matStepperIcon="edit">
                                <mat-icon>edit</mat-icon>
                            </ng-template>
                        </mat-horizontal-stepper>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>

    <!-- Spinner (replaces everything and waits until all requests have completed to show anything) -->
    <div *ngIf="this.isLoadingOrder" class="loading-spinner-overlay">
        <div class="loading-spinner-overlay">
            <mat-spinner></mat-spinner>
        </div>
    </div>
</div>
<!-- End of spinner -->