import { Step, StepValidationState } from "../../../../../interfaces/step";
import { Stepper } from "../../../../../interfaces/stepper";
import { RenewalComponent } from "projects/reg-hub-client/src/app/orders/renewal/renewal.component";
import { RenewalReviewComponent } from "projects/reg-hub-client/src/app/orders/renewal-review/renewal-review.component";

export class RenewalStepper implements Stepper {

    steps: Step[];

    constructor() {
        this.steps = [
            { title: "Details", content: RenewalComponent, validationState: StepValidationState.DefaultState },
            { title: "Review", content: RenewalReviewComponent, validationState: StepValidationState.DefaultState }
        ]
    }
}