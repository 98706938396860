import { Component, Input } from '@angular/core';
import { Constants } from 'projects/reg-hub-client/src/constants';
import { eDischargeType, Order, OrderManagerService, OrderUIConfiguration, Party, UIConfigurationService, Validation, ValidationProblem, ValidationService } from 'reg-hub-common';
import { FormControl } from '@angular/forms';
import { SteppedComponent } from 'projects/reg-hub-client/src/interfaces/stepped-component';
import { StepValidationState } from 'projects/reg-hub-client/src/interfaces/step';
import { Observable, of, takeUntil } from 'rxjs';

@Component({
  selector: 'app-discharge-review',
  templateUrl: './discharge-review.component.html',
  styleUrls: ['./discharge-review.component.css']
})
export class DischargeReviewComponent extends SteppedComponent {
  @Input() printMode: boolean = false;

  shouldShowAdditionalInformation: boolean = false;
  dischargeType: string = "";

  constructor(orderManager: OrderManagerService,
    validationService: ValidationService) {
    super(orderManager, validationService);
  }

  protected override init(order: Order): void {
    super.init(order);

    this.validationService.currentValidation$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(validation => this.validation$.next(validation));

    if (this.order.discharge?.dischargeType == eDischargeType.agreementToDischarge) {
      this.dischargeType = "Agreement to Discharge"
    } else if (this.order.discharge?.dischargeType == eDischargeType.totalDischarge) {
      this.dischargeType = "Total Discharge"
    }
  }

  override ngOnDestroy(): void {
    this.validationService.clearValidation();
    super.ngOnDestroy();
  }

  public override onSaving(): void {
    
  }

  public override pushErrors(errors: ValidationProblem[] | undefined): void {
    
  }

  public override getValidationState(errors: ValidationProblem[] | undefined, order: Order): StepValidationState {
    return (errors?.length ?? 0) === 0 ? StepValidationState.ValidationSuccess : StepValidationState.ValidationError;
  }

  isHistorical(): boolean {
    return this.order.orderStatusTypeID.includes('Historical');
  }

  protected getExpiryDateHeader(): string {
    return this.isHistorical() ? 'Expiry Date:' : 'Requested Expiry Date:';
  }
}