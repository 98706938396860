import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { Order, OrderFee, OrderFeeRepositoryService, OrderManagerService, SlideDownAnimation } from 'reg-hub-common';

@Component({
  selector: 'app-order-fees',
  templateUrl: './order-fees.component.html',
  styleUrls: ['./order-fees.component.css'],
  animations: [ SlideDownAnimation ]
})
export class OrderFeesComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();
  isComplete: boolean = false;
  orderFees: OrderFee[] = [];
  totalCost: number = 0;
  emptyGuid = '00000000-0000-0000-0000-000000000000';
  displayedColumns: string[] = ['description', 'amount', 'tax'];

  protected collapsed = true;
  protected collapseHasBeenToggled = false;

  constructor(private orderFeesRepo: OrderFeeRepositoryService,
    private orderManager: OrderManagerService
  ) { }

  ngOnInit(): void {
    this.orderManager.currentOrder
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(order => {
        this.getFees(order);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getFees(order: Order) {
    if (order != null && order.id != undefined) {
      this.isComplete = order.orderStatusTypeID == "Complete" || order.orderStatusTypeID == "Cancelled";
  
      // const fees$ = this.orderFeesRepo.getOrderFees(order.id);
      // const expectedFees$ = this.isComplete ? of([]) : this.orderFeesRepo.getExpectedOrderFees(order.id);
  
      // forkJoin({
      //   fees: fees$,
      //   expectedFees: expectedFees$
      // }).subscribe(({ fees, expectedFees }) => {
      //   this.orderFees = fees.concat(expectedFees);
      //   this.calculateTotal();
      // });

      this.orderFeesRepo.getCurrentAndExpectedOrderFees(order.id)
        .subscribe(fees => {
          //If the user has not toggled, then set toggle for them
          if (!this.collapseHasBeenToggled) {
            this.collapsed = fees.length > 3 || fees.length === 0;
          }

          //If they haven't toggled and we have more fees, then we need a timer to avoid
          //a potential big gross visual jump
          if (!this.collapseHasBeenToggled && fees.length > 3) {
            setTimeout(() => {
              this.orderFees = fees;
              this.calculateTotal();
            }, 500);
          } else {
            this.orderFees = fees;
            this.calculateTotal();
          }
        });
    }
  }

  calculateTotal(): void {
    this.totalCost = this.orderFees.reduce((acc, fee) => acc + fee.amount + fee.gst + fee.pst + fee.hst, 0);
  }

  getDescription(fee: OrderFee) {
    const feeType = fee.feeTypeID === "Disbursement" ? "Disb." : fee.feeTypeID;
    if (fee.productCode.substring(0, 2) === "_F")
    {
      return `FED ${feeType}`
    }
    else if (fee.productCode.substring(0, 2) === "_M")
    {
      return `Multi. ${feeType}`
    }
    else
    {
      return `${fee.productCode.substring(0, 2)} ${feeType}`;
    }
  }

  onCollapse() {
    this.collapsed = !this.collapsed;
    this.collapseHasBeenToggled = true;
  }
}
