import { Component, Input,OnDestroy,OnInit,ViewChild } from '@angular/core';
import { eOrderStatusType, OrderMetaDataRepositoryService } from 'reg-hub-common';
import { JurisdictionSelectionComponent, Order, OrderManagerService, eOrderType, eOrderTypeGroup, OrderRepositoryService, PatchDocument } from 'reg-hub-common';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-base-order-information-review',
  templateUrl: './base-order-information-review.component.html',
  styleUrls: ['./base-order-information-review.component.css']
})
export class BaseOrderInformationReviewComponent implements OnInit, OnDestroy {
  @Input() orderTypeGroupID!: eOrderTypeGroup;
  @ViewChild(JurisdictionSelectionComponent) jurisdictionSelectionComponent!: JurisdictionSelectionComponent;

  private unsubscribe$ = new Subject<void>();
  protected isEditingFileNumber = false;
  protected requestingUser: string = "";

  order: Order = { referenceNumber: "",
    country: "CA",
    jurisdiction: "",
    orderTypeID: eOrderType.notSet
  } as Order;

  constructor(
    private orderManager: OrderManagerService,
    private orderRepo: OrderRepositoryService,
    private orderMetaDataService: OrderMetaDataRepositoryService
  ) { }

  ngOnInit(): void {
    this.orderManager.currentOrder
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(order => {
      this.order = order;
      this.getOrderMetaData();
    });
  }

  ngOnDestroy(): void {
    // Signal all subscriptions to complete
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getOrderMetaData() {
    this.orderMetaDataService.getOrderMetaData(null, this.order.userGroupID, this.order.userID, null)
      .subscribe(metaData => {
        this.requestingUser = `${metaData.requestingUserFullName} (${metaData.requestingUserName})`;
      })
  }

  showQcForm(order: Order) {
    return order.orderTypeID !== eOrderType.amendment && !!order.lien?.qcFormTypeID
  }

  editReferenceNumber() {
    this.isEditingFileNumber = true;
  }

  saveReferenceNumber() {
    this.isEditingFileNumber = false;

    var patchDocument: PatchDocument[] = [
      {
        op: "replace",
        path: "referenceNumber",
        value: this.order.referenceNumber,
      }
    ]

    this.orderRepo.patchOrder(`Orders/${this.order.id}`, patchDocument)
    .subscribe();
  }

  orderIsInDraft() {
    return this.order.orderStatusTypeID == eOrderStatusType.draft || this.order.orderStatusTypeID == eOrderStatusType.historicalDraft;
  }
}
