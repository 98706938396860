import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Order, Party, OrderHistoryComponent, OrderUIConfiguration, OrderMetaDataRepositoryService, eOrderType } from 'reg-hub-common';
import { Constants } from '../../../../src/constants';
import { Observable, of, Subject } from 'rxjs';
import { OrdersHubService } from '../../services/hubs/orders-hub/orders-hub.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css']
})
export class OrderDetailsComponent implements OnInit, OnDestroy {
  @Input() order!: Order
  @Input() uiConfiguration!: OrderUIConfiguration;
  @Input() loading: boolean = true;
  orderTypeName: string = ""

  debtors!: Observable<Party[]>;
  securedParties!: Observable<Party[]>;
  dealers!: Observable<Party[]>;
  registeringAgents!: Observable<Party[]>;

  debtorsTitle: string = "Debtors";
  securedPartiesTitle: string = "Secured Parties";
  dealersTitle: string = "Dealers";
  registeringAgentsTitle: string = "Registering Agents";
  showEstate: boolean = false;
  requestingUser: string = "";

  private onDestroy$ = new Subject<void>();

  constructor(private dialog: MatDialog,
    private orderMetaDataService: OrderMetaDataRepositoryService,
    protected ordersHub: OrdersHubService) { }

  ngOnInit(): void {
    this.orderTypeName = this.order.orderType.name;
    this.debtors = of(this.getDebtors());
    this.securedParties = of(this.getSecuredParties());
    this.dealers = of(this.getDealers());
    this.registeringAgents = of(this.getRegisteringAgents());
    this.getOrderMetaData();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getOrderMetaData() {
    this.orderMetaDataService.getOrderMetaData(null, this.order.userGroupID, this.order.userID, null)
      .subscribe(metaData => {
        this.requestingUser = `${metaData.requestingUserFullName} (${metaData.requestingUserName})`;
      })
  }

  openOrderHistory() {
    this.dialog.open(OrderHistoryComponent, { data: this.order });
  }

  isSearchOrder(): boolean {
    return this.order.orderTypeID.toString() == "AssetSearch"
      || this.order.orderTypeID.toString() == "DebtorSearch"
      || this.order.orderTypeID.toString() == "CertifiedDebtorSearch";
  }

  isMultiSearchOrder(): boolean {
    return this.order.orderTypeID == eOrderType.assetMultiSearch;
  }

  isDischarge(): boolean {
    return this.order.orderTypeID.toString() == "Discharge"
      || this.order.orderTypeID.toString() == "BasicDischarge";
  }

  isRenewal(): boolean {
    return this.order.orderTypeID.toString() == "Renewal"
      || this.order.orderTypeID.toString() == "BasicRenewal";
  }

  isAmendment(): boolean {
    return this.order.orderTypeID.toString() == "Amendment";
  }

  showLifetime(): boolean {
    return (this.order.lien != null && 
      !this.isDischarge() &&
      this.order.orderStatusTypeID != "Cancelled");
  }

  getDebtors(): Party[] {
    return this.order.parties?.filter((party) => Constants.isDebtor(party.partyTypeID)) ?? [];
  }

  getSecuredParties(): Party[] {
    return this.order.parties?.filter((party) => Constants.isSecuredParty(party.partyTypeID)) ?? [];
  }

  getDealers(): Party[] {
    return this.order.parties?.filter((party) => Constants.isDealer(party.partyTypeID)) ?? [];
  }

  getRegisteringAgents(): Party[] {
    return this.order.parties?.filter((party) => Constants.isRegisteringAgent(party.partyTypeID)) ?? [];
  }
}
