<h2 mat-dialog-title>Confirm Submission</h2>
<mat-dialog-content>
    Are you sure you're ready to submit? <br/><br/>
    <mat-checkbox class="" [(ngModel)]="shouldReturnToBusinessSearchResults">Return to Search Results on submission</mat-checkbox>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close color="accent" [mat-dialog-close]="false"
        data-testid="negative-dialog-button">Close</button>
    <span class="spacer"></span>
    <button mat-button mat-dialog-close color="accent" [mat-dialog-close]="getDestination()"
        data-testid="positive-dialog-button">Submit</button>
</mat-dialog-actions>