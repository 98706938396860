import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { base64StringToBlob } from 'blob-util';
import { HttpParams } from '@angular/common/http';
import { trigger, transition, style, animate } from '@angular/animations';

import {
  InformationDocument,
  InformationDocumentsRepositoryService,
} from 'reg-hub-common';

@Component({
  selector: 'app-information-documents',
  templateUrl: './information-documents.component.html',
  styleUrls: ['./information-documents.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-in', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class InformationDocumentsComponent implements OnInit {
  allDocuments: InformationDocument[] = [];
  informationDocuments: InformationDocument[] = [];

  constructor(
    private repo: InformationDocumentsRepositoryService,
    private router: Router,
    private messageService: ToastrService
  ) {}

  // If this actually gets to be 100 files long,
  // we will want to do some scroll based lazy loading or paging.
  ngOnInit(): void {
    let params = new HttpParams()
      .set('customerVisible', 'true')
      .set('visible', 'true')
      .set('sortColumn', 'Priority')
      .set('pageSize', "100");

    this.repo.getFirstPageSubscribable(params).subscribe((results) => {
      this.allDocuments = results;
      this.informationDocuments = results;
    });
  }

  downloadDocument(informationDocumentID: string) {
    this.repo
      .getInformationDocumentBinary(informationDocumentID)
      .subscribe((result) => {
        const documentType = `application/${result?.documentTypeID ?? 'pdf'}`.toLowerCase();
        const fileName = result?.fileName ?? 'downloaded-file.pdf';
        const base64Data = result?.base64 ?? '';

        if (base64Data) {
          const blob = base64StringToBlob(base64Data, documentType);
          const blobURL = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = blobURL;
          link.download = fileName;
          link.click();
          this.messageService.success(`Downloading ${fileName}`);
        } else {
          this.messageService.error('Failed to download the document.');
        }
      });
  }

  filterDocuments(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const searchTerm = inputElement.value ?? '';

    if (!searchTerm) {
      this.informationDocuments = this.allDocuments;
    } else {
      const lowerSearchTerm = searchTerm.toLowerCase();
      this.informationDocuments = this.allDocuments.filter((doc) =>
        doc.name.toLowerCase().includes(lowerSearchTerm) ||
        (doc.description && doc.description.toLowerCase().includes(lowerSearchTerm))
      );
    }
  }
}