import { StepValidationState } from "../../../../../interfaces/step";
import { SearchStepper } from "../search-stepper";
import { BusinessReportCriteriaComponent } from "projects/reg-hub-client/src/app/orders/business-report-criteria/business-report-criteria.component";
import { BusinessReportReviewComponent } from "projects/reg-hub-client/src/app/orders/business-report-review/business-report-review.component";

export class _FBusinessReportStepper extends SearchStepper {

    constructor() {
        super()

        this.steps = [
            { title: "Report Criteria", content: BusinessReportCriteriaComponent, validationState: StepValidationState.DefaultState },
            { title: "Review", content: BusinessReportReviewComponent, validationState: StepValidationState.DefaultState }
        ];
    }
}