import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BusinessReportCriteria, EnumeratedTypesRepositoryService, Order, OrderManagerService, ValidationProblem, ValidationService } from 'reg-hub-common';
import { SteppedComponent } from 'projects/reg-hub-client/src/interfaces/stepped-component';
import { StepValidationState } from 'projects/reg-hub-client/src/interfaces/step';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { EnvironmentUrlService } from '../../services/environment-url/environment-url.service';

@Component({
  selector: 'app-business-report-criteria',
  templateUrl: './business-report-criteria.component.html',
  styleUrls: ['./business-report-criteria.component.css']
})
export class BusinessReportCriteriaComponent extends SteppedComponent {
  @Input() isFormEnabled: boolean = true;

  protected searchCriteriaDetails!: FormGroup;

  numberError$ = new BehaviorSubject<string | null | undefined>(null);
  nameError$ = new BehaviorSubject<string | null | undefined>(null);

  private orderToValidate: Order | null = null;

  constructor(
    private formBuilder: FormBuilder,
    private environmentUrl: EnvironmentUrlService,
    private enumeratedTypesRepo: EnumeratedTypesRepositoryService,
    orderManager: OrderManagerService,
    validationService: ValidationService) {
    super(orderManager, validationService);
  }

  protected override init(order: Order): void {
    super.init(order);

    const criteria = this.order.businessReportCriteria;
    this.searchCriteriaDetails = this.formBuilder.group({
      name: [criteria?.businessName],
      number: [criteria?.number]
    });

    if (!this.isFormEnabled) {
      this.searchCriteriaDetails.disable();
    }

    this.searchCriteriaDetails.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.validate(this.getOrderToValidate()));
  }

  public override getValidationState(errors: ValidationProblem[] | undefined, order: Order): StepValidationState {
    return (errors?.length ?? 0) === 0 ? StepValidationState.ValidationSuccess : StepValidationState.ValidationError;
  }

  public override onSaving(): void {
    const criteria = this.order.businessReportCriteria;

    if (!criteria) {
      return;
    }

    this.updateBusinessReportCriteria(criteria);
    this.order.businessReportCriteria = criteria;
    this.orderManager.updateOrder(this.order);
  }

  override pushErrors(errors: ValidationProblem[] | undefined): void {
    if (!errors) {
      return;
    }

    this.numberError$.next(errors.filter(error => error.path.includes('/number'))?.at(0)?.userFriendlyMessage);
    this.nameError$.next(errors.filter(error => error.path.includes('/businessname'))?.at(0)?.userFriendlyMessage);
  }

  private getOrderToValidate(): Order {
    if (!this.orderToValidate) {
      this.orderToValidate = this.copyOrder(this.order);
    }

    const criteria = this.orderToValidate?.businessReportCriteria;

    if (!criteria) {
      return this.orderToValidate!;
    }

    this.updateBusinessReportCriteria(criteria);
    this.orderToValidate!.businessReportCriteria = criteria;

    return this.orderToValidate!;
  }

  private updateBusinessReportCriteria(criteria: BusinessReportCriteria): void {
    criteria.number = this.searchCriteriaDetails.get('number')!.value;
    criteria.businessName = this.searchCriteriaDetails.get('name')!.value;
  }
}
