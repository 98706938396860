import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable, from, lastValueFrom } from 'rxjs';
import { Constants } from '../../../../constants';
import { ClientAuthService } from '../client-auth.service';
import { EnvironmentUrlService } from '../../environment-url/environment-url.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService {

  constructor(private authService: ClientAuthService,
    private environmentService: EnvironmentUrlService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(req.url.startsWith(this.environmentService.urlAddress) ||
        req.url.startsWith(this.environmentService.consortiumUrlAddress) || 
        req.url.startsWith(this.environmentService.batchUrlAddress)) {
        var token = this.authService.getAccessToken();
        const authRequest = req.clone({ headers: req.headers.set('Authorization', `Bearer ${token}`) });
        return next.handle(authRequest);
    }
    else {
      return next.handle(req);
    }
  }
}
